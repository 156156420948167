@font-face {
  font-family: 'BaiJamjureeBold';
  src: url('../media/fonts/BaiJamjuree-Bold.ttf');
}

@font-face {
  font-family: 'BaiJamjureeBoldItalic';
  src: url('../media/fonts/BaiJamjuree-BoldItalic.ttf');
}

@font-face {
  font-family: 'BaiJamjureeExLight';
  src: url('../media/fonts/BaiJamjuree-ExtraLight.ttf');
}

@font-face {
  font-family: 'BaiJamjureeExLightItalic';
  src: url('../media/fonts/BaiJamjuree-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'BaiJamjureeItalic';
  src: url('../media/fonts/BaiJamjuree-Italic.ttf');
}

@font-face {
  font-family: 'BaiJamjureeLight';
  src: url('../media/fonts/BaiJamjuree-Light.ttf');
}

@font-face {
  font-family: 'BaiJamjureeLightItalic';
  src: url('../media/fonts/BaiJamjuree-LightItalic.ttf');
}

@font-face {
  font-family: 'BaiJamjureeMedium';
  src: url('../media/fonts/BaiJamjuree-Medium.ttf');
}

@font-face {
  font-family: 'BaiJamjureeMediumItalic';
  src: url('../media/fonts/BaiJamjuree-MediumItalic.ttf');
}

@font-face {
  font-family: 'BaiJamjureeRegular';
  src: url('../media/fonts/BaiJamjuree-Regular.ttf');
}

@font-face {
  font-family: 'BaiJamjureeSemiBold';
  src: url('../media/fonts/BaiJamjuree-SemiBold.ttf');
}

@font-face {
  font-family: 'BaiJamjureeSemiBoldItalic';
  src: url('../media/fonts/BaiJamjuree-SemiBoldItalic.ttf');
}
