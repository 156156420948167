// ---- Customize Ionic styles ----

ion-toolbar {
  --padding-start: 20px;
}

ion-back-button::part(native) {
  padding: 0;
}

.item-style,
.detail-item-style,
.head-item-style,
.subhead-item-style {
  --background: transparent;
  --border-color: transparent;
}

.item-style,
.detail-item-style {
  font-family: BaiJamjureeMedium;
}

.head-item-style {
  font-family: BaiJamjureeBold;
}

.subhead-item-style {
  font-family: BaiJamjureeSemiBold;
}

ion-button::part(native) {
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    background: inherit;
    border-radius: inherit;
    filter: blur(0.35rem);
    opacity: 0.5;
  }
}

ion-item.no-padding::part(native) {
  --padding-start: 0;
  --inner-padding-end: 0;
}

ion-input,
ion-button,
ion-searchbar,
button.item-native {
  border-radius: 6px;
  --border-radius: 6px;
}

ion-card {
  margin: 0.3rem;
  border-radius: var(--app-card-border-radius);
}

ion-textarea textarea {
  padding: 0.625rem !important;
}

ion-searchbar {
  padding-left: 0 !important;
}

ion-text.ion-text-center {
  display: block;
}

// ---- Custom global styles ----

.avatar-style {
  --border-radius: 6px;
  aspect-ratio: 1/1;
}

.item-transparent-border {
  --border-color: transparent;
  font-family: BaiJamjureeMedium;
}

// Login, Signup, and Forgot Password pages

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    z-index: -1000;
    opacity: 0.5;
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

* {
  // Fallback to --ion-font-family
  font-family: BaiJamjureeRegular, sans-serif;
}
