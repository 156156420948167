/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root,
:host {
  /** primary **/
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 255, 51, 51;
  --ion-color-primary-contrast: #01d0ff;
  --ion-color-primary-contrast-rgb: 245, 245, 245;
  --ion-color-primary-shade: #000000;
  --ion-color-primary-tint: #000000;
  /** secondary **/
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 245, 245, 245;
  --ion-color-secondary-contrast: #363636;
  --ion-color-secondary-contrast-rgb: 54, 54, 54;
  --ion-color-secondary-shade: #ffffff;
  --ion-color-secondary-tint: #ffffff;

  /** tertiary **/
  --ion-color-tertiary: #e9e9e9;
  --ion-color-tertiary-rgb: 233,233,233;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #cdcdcd;
  --ion-color-tertiary-tint: #ebebeb;

  /** success **/
  --ion-color-success: #138948;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #01d0ff;
  --ion-color-dark-rgb: 54, 54, 54;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 255, 51, 51;
  --ion-color-dark-shade: #01d0ff;
  --ion-color-dark-tint: #01d0ff;

  /** medium **/
  --ion-color-medium: #757575;
  --ion-color-medium-rgb: 117, 117, 117;
  --ion-color-medium-contrast: #f5f5f5;
  --ion-color-medium-contrast-rgb: 54, 54, 54;
  --ion-color-medium-shade: #48485f;
  --ion-color-medium-tint: #565679;

  /** light **/
  --ion-color-light: #f5f5f5;
  --ion-color-light-rgb: 245, 245, 245;
  --ion-color-light-contrast: #dbdbdb;
  --ion-color-light-contrast-rgb: 219, 219, 219;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: BaiJamjureeRegular;

  --ion-item-background: var(--ion-color-secondary);

  --border-radius: 6px;
  /** ---- Custom variables ---- **/
  --app-card-border-radius: 8px;

  --ion-tab-bar-color-selected: var(--ion-color-primary-contrast)
}

/* main bg color */
ion-content {
  --background: var(--ion-color-tertiary);
}

/** changing default arrow icon appearance **/
ion-item {
  --detail-icon-color: var(--ion-color-primary);
  --detail-icon-opacity: 1;
}
