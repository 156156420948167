.mod-alert {
  .alert-wrapper {
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    text-align: center;
  }

  .alert-title {
    color: var(--ion-color-dark);
    text-align: center;
  }

  .alert-button {
    margin: auto;
    font-family: 'BaiJamjureeBold';
  }
}
